import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { IDocStorage } from '../../../Marketing/studio/StudioApp'
import { ICampaign, IRecruiter } from '../../CampaignTable'
import CampaignFlyerForm from './CampaignFlyerForm'
import CampaignInfoForm from './CampaignInfoForm'
import CampaignRecruiterForm from './CampaignRecruiterForm'
import CampaignSendForm from './CampaignSendForm'

export interface ICampaignProps {
  name: string
  description: string
  start_date?: Date
  end_date?: Date
  level: 'organisation' | 'goal' | 'unit'
  goal?: string
  unit?: string
}
interface CampaignCreationModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  submitInfoCallback: (values: ICampaignProps) => Promise<void>
  onClose: () => void
  initialValues: () => ICampaignProps
  mostRecentCampaign: ICampaign | undefined
  handleUpload: (recruiters: { name: string; email: string }[]) => Promise<void>
  handleUploadFlyer: (doc: IDocStorage) => Promise<void>
  campaignId?: string
  recruiters: IRecruiter[]
  handleSendtoRecruiters: (recruiters: IRecruiter[]) => Promise<void>
}

type ICampaignCreationStage = 'info' | 'recruiters' | 'flyer' | 'send'

const CampaignCreationModal: React.FC<CampaignCreationModalProps> = ({
  showModal,
  setShowModal,
  initialValues,
  submitInfoCallback,
  mostRecentCampaign,
  handleUpload,
  handleUploadFlyer,
  campaignId,
  recruiters,
  handleSendtoRecruiters,
}) => {
  const [stage, setStage] = React.useState<ICampaignCreationStage>('info')
  const [infoValues, setInfoValues] = React.useState<ICampaignProps>(initialValues())
  const navigate = useNavigate()

  useEffect(() => {
    const submitInfo = async () => {
      if (infoValues.name !== '' && stage === 'info') {
        await submitInfoCallback(infoValues)
        setStage('recruiters')
      }
    }
    submitInfo()
  }, [infoValues.name])

  useEffect(() => {
    if (stage === 'recruiters' && mostRecentCampaign?._id) {
      // handleNextStage()
    }
  }, [mostRecentCampaign?._id])

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
        setStage('info')
        setInfoValues(initialValues())
      }}
      size='lg'
      keyboard={false}
    >
      <div className='modal-body'>
        <div>
          <h3 className='fw-bolder my-2'>Opprett kampanje </h3>
          {stage === 'info' && (
            <CampaignInfoForm initialValues={initialValues} submitCallback={setInfoValues} />
          )}
          {stage === 'recruiters' && (
            <CampaignRecruiterForm
              submitForm={() => {
                setStage('flyer')
              }}
              handleUpload={handleUpload}
              stage='new'
            />
          )}
          {stage === 'flyer' && (
            <CampaignFlyerForm
              submitForm={() => {
                if (!recruiters?.length) navigate(`/campaignsingle/${campaignId}`)
                else setStage('send')
              }}
              handleSave={async (doc) => {
                await handleUploadFlyer(doc)
              }}
              campaignId={campaignId || ''}
              hasRecruiters={!!recruiters?.length}
            />
          )}
          {stage === 'send' && (
            /* recruiters?.length && */ <CampaignSendForm
              submitForm={async (sendRecruiters) => {
                await handleSendtoRecruiters(sendRecruiters)
                // close modal and go to campaign
                setShowModal(false)
                navigate(`/campaignsingle/${campaignId}`)
              }}
              recruiters={recruiters || []}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CampaignCreationModal)
